import React from 'react';
import data from './listProjects';
import Project from './Project.jsx';
import './Projects.scss'

import github_logo from '../../images/logo-list/github.png';

/**
 * Permet d'afficher la liste des projets
 */
const Projects = () => {
  return (
    <div className="projects-container text-center" id="Projects">
      <table className="centered">
        <tbody>
          <tr>
            <td className="align-middle">
              <h1>Certains de mes projets</h1>
              <p>
                Voici certains de mes projets personnels disponibles en ligne
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      
      <div className="d-lg-flex justify-content-lg-around">
          {data.map((project)=>(
              <div key={project.id} className={project.className}>
                  <Project project={project}/>
              </div>
          ))}    
      </div>

      <table className="centered">
        <tbody>
          <tr>
            <td className="align-middle">
              <h2>D'autre projets ?</h2>
              <p>
                Retrouvez le reste de mon travail sur <a href="https://github.com/ValentinM27">GitHub <img className="logo-git" src={github_logo} alt="Logo Github"/></a>
              </p>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  )
}

export default Projects