import React from 'react';
import './Project.scss';

/**
 * Composant d'un projet 
 */
const Project = (props) => {
  return (
    <div className="project p-2 " id={props.project.className}>
        <div className="front text-center">
            {props.project.name}
        </div>

        <div className="back">
            {props.project.desc}

            <a href={props.project.link} target="_blank" rel="noreferrer" className="button">Voir</a>
        </div>
    </div>
  )
}

export default Project