import React from 'react';
import './Contact.scss';

// Import des logos
import linkedin_logo from './images/linkedin.png';
import instagram_logo from './images/instagram.png';
import mail_logo from './images/mail.png';

/**
 * Composant Contact
 */
const Contact = () => {
  return (
    <div className="contact-container text-center" id="Contact">
      <table className="centered">
        <tbody>
          <tr>
            <td className="align-middle">
              <h1>Pour me contacter</h1>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="d-flex justify-content-center">
        <div className="p-2 bd-highlight">
          <a href="https://www.linkedin.com/in/valentin-marguerie/" target="_blank" rel="noopener noreferrer"><img src={linkedin_logo} className="logo-contact" alt="Logo LinkedIn"/></a>
        </div>

        <div className="p-2 bd-highlight">
          <a href="https://www.instagram.com/valentin_marguerie/" target="_blank" rel="noopener noreferrer"><img src={instagram_logo} className="logo-contact" alt="Logo Instagram"/></a>
        </div>

        <div className="p-2 bd-highlight">
          <a href="mailto:contact@valentinmarguerie.fr"><img src={mail_logo} className="logo-contact" alt="Logo Mail"/></a>
        </div>
      </div>
    </div>
  )
}

export default Contact