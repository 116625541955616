import { useState, useEffect } from "react";
import "./App.scss";
import {
  Navigation,
  Main,
  About,
  Footer,
  Projects,
  Contact,
} from "./components/index.js";

import "bootstrap/dist/css/bootstrap.min.css";

import main_image from "./images/main.jpeg";
import hero from "./images/hero.png";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div>
      <div className={loading ? "loader" : "hide"}>
        <table className="centered">
          <tbody>
            <tr>
              <td className="align-middle">
                <div className="loading">
                  <div className="loading-text">
                    <span className="loading-text-words">L</span>
                    <span className="loading-text-words">O</span>
                    <span className="loading-text-words">A</span>
                    <span className="loading-text-words">D</span>
                    <span className="loading-text-words">I</span>
                    <span className="loading-text-words">N</span>
                    <span className="loading-text-words">G</span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={loading ? "hide" : "App"}>
        {/** Navigation */}
        <Navigation />

        {/** Contenu */}
        <Main main_image={main_image} hero={hero} />
        <About />
        <Projects />
        <Contact />

        {/** Footer */}
        <Footer />
      </div>
    </div>
  );
}

export default App;
