let data;

export default data = [
  {
    id: 1,
    name: "Blog",
    desc: "Site dédié à la rédaction d'articles",
    link: "https://blog.valentinmarguerie.fr/",
    className: "blog",
  },
  {
    id: 2,
    name: "Portfolio",
    desc: "Le site sur lequel vous vous trouvez",
    link: "https://valentinmarguerie.fr",
    className: "portfolio",
  },
  {
    id: 3,
    name: "Le Monde RSS",
    desc: "Site pour suivre l'actualité via Le Monde",
    link: "https://www.rss.valentinmarguerie.fr/",
    className: "rss",
  },
];
