import React from "react";
import "./About.scss";

//Logo liste front
import scss_logo from "../../images/logo-list/scss.png";
import vue_logo from "../../images/logo-list/vue.png";
import react_logo from "../../images/logo-list/react.png";

//Logo liste back
import laravel_logo from "../../images/logo-list/laravel.png";
import node_logo from "../../images/logo-list/node.png";
import php_logo from "../../images/logo-list/php.png";

//Logo liste infra et data
import postgres_logo from "../../images/logo-list/postgres.png";
import docker_logo from "../../images/logo-list/docker.jpeg";
import linux_logo from "../../images/logo-list/linux.png";

/**
 * Composant About
 */
const About = () => {
  return (
    <div className="col-md-12 centered text-center about" id="About">
      <div className="about-container">
        <div className="content">
          <table className="centered">
            <tbody>
              <tr>
                <td className="align-middle">
                  <h2 className="orange">Salut, moi c'est Valentin,</h2>
                  <p>
                    Développeur Web, j'ai découvert l'informatique durant
                    l'année 2020. <br />
                    C'est depuis, devenu pour moi une vraie passion. Mon domaine
                    de préférence est le web, de la création et du design de
                    l'interface graphique à la gestion du back-end !
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="tech centered">
          <div className="container">
            <h2>Mes technos favorites</h2>

            <div className="row">
              <div className="col-lg">
                <h3>Front-End</h3>
                <div className="tech-list">
                  <ul>
                    <li>
                      SCSS
                      <img
                        className="logo-about"
                        src={scss_logo}
                        alt="logo scss"
                      />
                    </li>
                    <li>
                      Vue
                      <img
                        className="logo-about"
                        src={vue_logo}
                        alt="logo Vue"
                      />
                    </li>
                    <li>
                      React
                      <img
                        className="logo-about"
                        src={react_logo}
                        alt="logo React"
                      />
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg">
                <h3>Back-End</h3>
                <div className="tech-list">
                  <ul>
                    <li>
                      Node.JS
                      <img
                        className="logo-about"
                        src={node_logo}
                        alt="logo Node.JS"
                      />
                    </li>
                    <li>
                      PHP
                      <img
                        className="logo-about"
                        src={php_logo}
                        alt="logo php"
                      />
                    </li>
                    <li>
                      Laravel
                      <img
                        className="logo-about"
                        src={laravel_logo}
                        alt="logo Laravel"
                      />
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg">
                <h3>Data et infra</h3>
                <div className="tech-list">
                  <ul>
                    <li>
                      Docker
                      <img
                        className="logo-about"
                        src={docker_logo}
                        alt="logo Docker"
                      />
                    </li>
                    <li>
                      PostgreSQL
                      <img
                        className="logo-about"
                        src={postgres_logo}
                        alt="logo PostgreSQL"
                      />
                    </li>
                    <li>
                      Linux
                      <img
                        className="logo-about"
                        src={linux_logo}
                        alt="logo Linux"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
