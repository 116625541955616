import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import logo_nav from '../../images/main-logo.png';
import './Navigation.scss';


/**
 * Composant de la barre de navigation
 */
const Navigation = () => {
  return (
    <div>
        <Navbar className="navbar" sticky="top" expand="xl">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        alt="Logo Valentin"
                        src={logo_nav}
                        width="50"
                        height="50"
                        className="d-inline-block align-top logo"
                    />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse className="text-center" id="basic-navbar-nav">
                    <Nav className="ms-auto float-left">
                        <Nav.Link href="#About">Qui suis-je ?</Nav.Link>
                        <Nav.Link href="#Projects">Mes projets</Nav.Link>
                        <Nav.Link href="#Contact">Contact</Nav.Link>
                    </Nav> 
                </Navbar.Collapse>

            </Container>
        </Navbar>
    </div>
  )
}

export default Navigation
