import React from 'react';
import './Footer.scss';

/**
 * Composant Footer
 */
const Footer = () => {
  return (
    <div className="footer-container centered text-center">
        <div className="content">
            Site conçu et réalisé par Valentin Marguerie &copy; 2022
        </div> 
    </div>
  )
}

export default Footer