import React from "react";
import "./Main.scss";

/**
 * Composant principal
 */
const Main = (props) => {
  return (
    <div className="main-container col-md-12 w-75 centered text-center">
      <div className="headline">
        Front End & Back End Developer, <br />
        Vue, Laravel, Docker ...
      </div>

      <div className="text mar-top">
        Je conçois des applications web pour vos besoins !
      </div>

      <div className="main-image">
        <img src={props.main_image} alt="illustration" />
      </div>

      <div className="main-hero">
        <img src={props.hero} alt="illustration hero" />
      </div>
    </div>
  );
};

export default Main;
